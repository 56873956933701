import React from 'react';
import { fmkConfirmOrder } from '../../locale/locale.json';
import styles from './confirm-order.module.scss';
import { useStateValue } from '../../store/state';
import ConfirmOrderForm from './confirm-order-form';
import pageAliases from '../../data/pageAliases';
import getWindow from '../../libs/getWindow';
import navigate from '../../libs/navigate';

const ConfirmOrder = () => {
  const [state] = useStateValue();
  const { lineItemsCount } = state.cart.fmk;
  if (lineItemsCount === 0 && getWindow('location.search')) {
  // if (lineItemsCount === 0) {
    navigate(pageAliases.festiveMealKits);
  }
  return (
    <div className={styles.confirmOrderMain}>
      <div className={styles.confirmOrderBody}>
        <div className={styles.confirmOrderTitle}>{fmkConfirmOrder.title}</div>
        <div
          className={styles.confirmOrderSubtitle}
          dangerouslySetInnerHTML={{ __html: fmkConfirmOrder.subtitle }}
        ></div>
        <ConfirmOrderForm />
      </div>
    </div>
  );
};

export default ConfirmOrder;
