// Libs.
import React from 'react';
// Deps.
import ConfirmOrder from '../../components/fmk/confirm-order';
import Layout from '../../components/layout/layout';
import SEO from '../../components/layout/seo';

const ConfirmOrderPage = () => {
  return (
    <Layout>
      <SEO title="Festive Meal Kits Confirm Order" />
      <ConfirmOrder />
    </Layout>
  );
};

export default ConfirmOrderPage;
