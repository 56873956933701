import * as yup from 'yup';
import { useStoresStaticQuery } from '../../hooks/useStoresStaticQuery';
import { compact, map } from 'lodash';

export const FestiveConfirmSchema = () => {
  const allStores = useStoresStaticQuery();
  const storeList = map(
    compact(map(allStores, (node) => (node.crmId ? { id: node.crmId, label: node.title } : false))),
    'label'
  );
  const StoreNumber = yup.mixed().oneOf(storeList);

  return yup.object().shape({
    StoreNumber: yup.string().required(),
    PickUpTime: yup.string().required(),
  });
};
