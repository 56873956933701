import React, { useEffect, useState, useRef } from 'react';
import useForm from 'react-hook-form';
import moment from 'moment';
import { orderBy, get } from 'lodash';
import DatePicker from 'react-date-picker';
import { FestiveConfirmSchema } from './festive-confirm-schema';
import { SetPickup } from '../../store/cart/action-creators';
import { useStateValue } from '../../store/state';
import { fmkConfirmOrder, calendar } from '../../locale/locale';
import { useStoresStaticQuery } from '../../hooks/useStoresStaticQuery';

import pageAliases from '../../data/pageAliases';
import styles from './confirm-order-form.module.scss';
import {
  getPickUpStores,
  getPickUpDatesRange,
  getPickUpHourRange,
} from '../../libs/store/store-utils';
import navigate from '../../libs/navigate';

const ConfirmOrderForm = () => {
  const [, dispatch] = useStateValue();
  const calendarFix = useRef(null);
  const [globalError, setGlobalError] = useState('');
  const [isFormValid, setFormValid] = useState(false);
  const validationSchema = FestiveConfirmSchema();

  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const [availablepickupTime, setAvailablepickupTime] = useState([]);

  const [storeID, setStoreID] = useState({ value: '', error: false });
  const [pickupDate, setPickupDate] = useState({ value: null, error: false });
  const [pickupTime, setPickupTime] = useState({ value: '', error: false });

  const [pickUpDateRangeStart, setPickUpDateRangeStart] = useState(moment().add(1, 'days').toDate());
  const [pickUpDateRangeEnd, setPickUpDateRangeEnd] = useState(format(new Date()));

  let getPickUpDateRange;

  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    defaultValues: {
      PickUpTime: '',
      StoreNumber: '',
    },
    validationSchema: validationSchema,
    submitFocusError: true,
    nativeValidation: false,
  });

  let allStores = useStoresStaticQuery();
  let allPickUpStores = getPickUpStores(allStores);

  allPickUpStores = orderBy(allPickUpStores, ['title'], ['asc']);
  useEffect(() => {
    for (let elem of get(calendarFix, 'current.wrapper').querySelectorAll('input')) {
      elem.setAttribute('autocomplete', 'off');
      elem.setAttribute('list', 'autocompleteOff');
    }
  }, []);

  useEffect(() => {
    if (storeID.value !== '') {
      setGlobalError('');
      getPickUpDateRange = getPickUpDatesRange(allStores, storeID.value);
      if (moment().isAfter(moment(getPickUpDateRange.value, 'YYYY-MM-DD'))) {
        // noinspection JSCheckFunctionSignatures
        setPickUpDateRangeStart(moment().add(1, 'days').toDate());
      } else {
        // noinspection JSCheckFunctionSignatures
        setPickUpDateRangeStart(moment(getPickUpDateRange.value, 'YYYY-MM-DD').toDate());
      }
      setPickUpDateRangeEnd(moment(getPickUpDateRange.end_value, 'YYYY-MM-DD').toDate());
    }
    validateForm();
  }, [storeID]);

  useEffect(() => {
    if (!!pickupDate.value) {
      let date = pickupDate.value;
      date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      const day = dayNames[date.getDay()];
      const getPickUpHoursRange = getPickUpHourRange(allStores, storeID.value, day);
      if (getPickUpHoursRange.length === 0) {
        setPickupTime({ value: '', error: true });
        setGlobalError(fmkConfirmOrder.storeClosedError);
      } else {
        setGlobalError('');
        setPickupTime({ value: '', error: false });
        const hours = [];
        getPickUpHoursRange.forEach((slot) => {
          let start = slot.starthours;
          while (start <= slot.endhours) {
            let hr = Math.floor(start / 100);
            let mns = start % 100;
            mns = mns.toString().length === 1 ? `${mns}0` : `${mns}`;
            hours.push(`${hr}:${mns}`);
            start += 100;
          }
        });
        // This is where we can potentially hide the hours dropdown if we find that there is only one
        // opening hours (ex: 1AM-1AM)
        //console.log(hours);
        setAvailablepickupTime(hours);
      }
      validateForm();
    }
  }, [pickupDate]);

  useEffect(() => {
    validateForm();
  }, [pickupTime]);

  // Change the date format.
  function format(setDate) {
    let date = new Date(setDate);
    if (date.getTime()) {
      date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      return moment(date).format('MM/DD/YYYY');
    }
  }

  const validateForm = () => {
    setFormValid(false);
    if (storeID.value !== '' && !!pickupTime.value && pickupTime.value !== '') {
      setFormValid(true);
    }
  };
  const onStoreSelected = ($event) => {
    setPickupDate({ value: '', error: false });
    setValue('PickUpTime', '');
    if ($event.target.value !== '') {
      setStoreID({ value: $event.target.value, error: false });
      setValue('StoreNumber', `${$event.target.value}`);
    } else {
      setStoreID({ value: '', error: false });
    }
  };

  const onDateSelected = (date) => {
    setValue('PickUpTime', '');
    if (date) {
      setValue(
        'PickUpDate',
        `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        true,
      );
      setPickupDate({ value: date, error: false });
    } else {
      setValue('PickUpDate', '', true);
    }
  };

  const onPickupTimeSelected = (data) => {
    if (data['PickUpTime'] !== '') {
      setPickupTime({ value: data['PickUpTime'], error: false });
      setValue('PickUpDate', `${data['PickUpTime']}`);
    }
  };

  const today = moment();
  const filteredPickUpStores = allPickUpStores.filter((store) => {
    const storeEndValue = get(store, 'pickUpDate.end_value', null);
    return storeEndValue && moment(storeEndValue, 'YYYY-MM-DD').isSameOrAfter(today, 'day');
  });

  const onSubmit = () => {
    if (isFormValid) {
      const pickup = {};
      pickup.storeId = storeID.value;
      pickup.date = format(pickupDate.value);
      pickup.time = pickupTime.value;

      dispatch(SetPickup(pickup, 'fmk'));
      navigate(pageAliases.festiveMealKitsCheckout);
    }
  };
  const calendarDisabled = moment(pickUpDateRangeStart, 'YYYY-MM-DD').isSameOrAfter(moment(pickUpDateRangeEnd, 'YYYY-MM-DD'), 'day');
  return (
    <div className={styles.confirmOrderFormWrapper}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <input type="text" name="PickUpDate" hidden={true} ref={register} />

        {globalError !== '' && (
          <div tabIndex={0} className={styles.confirmOrderFormGlobalError}>
            {globalError}
          </div>
        )}

        <div className={styles.confirmOrderForm}>
          <label>{fmkConfirmOrder.locationTitle}</label>
          <div className={styles.confirmOrderFormSelect}>
            <select
              name="StoreNumber"
              ref={register}
              onChange={onStoreSelected}
              onSelect={onStoreSelected}
            >
              <option value="">{fmkConfirmOrder.storeDropdownDefault}</option>
              {filteredPickUpStores.map((store, key) => {
                return (
                  <option key={key} value={store.storeId}>
                    {store.title}
                  </option>
                );
              })}
            </select>
          </div>

          <label>{fmkConfirmOrder.dateTitle}</label>
          <div className={styles.orderAddMissingPointsCalendarContainer}
            data-disabled={(storeID.value === '' || storeID.error) || calendarDisabled}>
            <DatePicker
              format={'MM/dd/yyyy'}
              onChange={onDateSelected}
              calendarType="US"
              disabled={(storeID.value === '' || storeID.error) || calendarDisabled}
              calendarClassName={[styles.calendarBody]}
              className={[
                styles.calendarBase,
                pickupDate.value ? styles.calendarBaseDateSelected : '',
              ]}
              clearIcon={null}
              value={pickupDate.value === '' && moment().isBefore(pickUpDateRangeStart, 'month') ? pickUpDateRangeStart : pickupDate.value}
              minDate={pickUpDateRangeStart}
              maxDate={new Date(pickUpDateRangeEnd)}
              monthAriaLabel={calendar.monthAriaLabel}
              dayAriaLabel={calendar.dayAriaLabel}
              yearAriaLabel={calendar.yearAriaLabel}
              nativeInputAriaLabel={calendar.nativeInputAriaLabel}
              calendarAriaLabel={calendar.calendarAriaLabel}
              ref={calendarFix}
            />
          </div>

          <div className={styles.confirmOrderFormSelect}>
            <label>{fmkConfirmOrder.timeTitle}</label>
            <select
              name="PickUpTime"
              ref={register}
              onChange={handleSubmit(onPickupTimeSelected)}
              disabled={!pickupDate.value || pickupDate.error || pickupTime.error}
            >
              <option value="">{fmkConfirmOrder.selectTime}</option>
              {availablepickupTime.map((slot) => (
                <option value={slot} key={slot}>
                  {moment(slot, 'h:m').format('ha')}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button
          type={`submit`}
          disabled={!isFormValid || globalError !== ''}
          className={styles.confirmOrderFormButton}
          aria-label={fmkConfirmOrder.btnLabel}
        >
          {fmkConfirmOrder.btnLabel}
        </button>
      </form>
    </div>
  );
};

export default ConfirmOrderForm;
